import * as React from "react"

function IconUser(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill="none"
      {...props}
      className={`icon ${size}`}
      >
      <g clipPath="url(#a)">
        <path
          fill="#000"
          d="M16 0C7.164 0 0 7.165 0 16s7.164 16 16 16c8.837 0 16-7.164 16-16S24.837 0 16 0Zm0 4.785a5.292 5.292 0 1 1 0 10.584 5.292 5.292 0 0 1 0-10.584Zm-.004 23.031a11.742 11.742 0 0 1-7.646-2.82 2.255 2.255 0 0 1-.791-1.714c0-2.963 2.398-5.335 5.363-5.335h6.158a5.33 5.33 0 0 1 5.354 5.335 2.25 2.25 0 0 1-.79 1.714 11.738 11.738 0 0 1-7.647 2.82Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconUser