interface PropsCookie {
  cname: string;
  cvalue: string;
  exdays?: number;
}

interface PropsCookieName {
  cname: string;
}

export function setCookie({cname, cvalue, exdays}: PropsCookie) {
  const d = new Date();
  const exdays_defined = exdays ? exdays : 1;
  d.setTime(d.getTime() + (exdays_defined * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie({cname}: PropsCookieName) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getSession() {
  const session_token = (getCookie({cname:'session_token'})) ? getCookie({cname:'session_token'}) : '';
  const session_token_decoded = session_token.substring(3, session_token.length);
  const session_token_json = (getCookie({cname:'session_token'})) ? JSON.parse(decodeString(session_token_decoded)):{account: {account_id: null}};
  const account = session_token_json.account;
  const custom = session_token_json.custom;
  const data = {
    account,
    custom,
  }
  return data;
}

export function setSession({ user }:any) {
  const account = user ? user : { account_id: null };
  const custom = {};
  const data = {
    account,
    custom
  }
  const session_token = 'rm-'+encodeString(JSON.stringify(data));
  setCookie({cname:'session_token', cvalue: session_token});
}

export function updateSession({ custom }:any) {
  const session = getSession();
  const data = {
    account: session.account,
    custom: custom,
  }
  const session_token = 'rm-'+encodeString(JSON.stringify(data));
  setCookie({cname:'session_token', cvalue: session_token});
}

/* Encode string */
export function encodeString(string:string) {
  var encodedString = btoa(encodeURI(string));
  return encodedString;
}

/* Uncoded string */
export function decodeString(encodedString:string) {
  var decodedString = decodeURI(atob(encodedString));
  return decodedString;
}


/*
export function checkCookie() {
  let user = getCookie("username");
  if (user != "") {
    alert("Welcome again " + user);
  } else {
    user = prompt("Please enter your name:", "");
    if (user != "" && user != null) {
      setCookie("username", user, 365);
    }
  }
}*/