import * as React from "react"

function IconLogo(props: any) {
  const size = (props.size) ? props.size : '';

  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`logo ${size}`}
    >
    </svg>
  )
}

export default IconLogo
