import React from 'react'
// import style from './Footers.css';
import * as Icon from '../icons/Icons';

interface Props {
  companyName?: string;
  children?: any;
  linkPrivacy?: string;
  linkTerms?: string;
  linksSocial?: any;
}

export const Footer = ({ companyName, children, linkPrivacy, linkTerms, linksSocial }: Props) => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer'>
      <div className='wrapper-nav'>{children}</div>
      <div className='wrapper-copyright'>
        <div>
          <div className='text-company-name'>© {year} {companyName}</div>
          <div className='text-terms-privacy'>
            <a href={linkPrivacy} target="_blank">Política de privacidad</a> | <a href={linkTerms} target="_blank">Términos y condiciones</a>
          </div>
        </div>
      </div>
      <div className='wrapper-social'>
        <div className="social-nav-wrapper">
          {linksSocial}
        </div>
      </div>
    </footer>
  )
}