import * as React from "react"

function IconMenu(props: any) {
  const size = (props.size) ? props.size : 'normal';

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`icon ${size}`}
    >
      <path
        d="M3.5 21h21v-2.333h-21V21Zm0-5.833h21v-2.334h-21v2.334ZM3.5 7v2.333h21V7h-21Z"
        fill="#4E73BC"
      />
    </svg>
  )
}

export default IconMenu
