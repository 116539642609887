
import {
  setCookie,
  getCookie,
  setSession,
  getSession,
  updateSession, 
  encodeString,
  decodeString } from './cookie/Cookie';
// import { getUserSession } from './user/session';
import { 
  slugify,
  amountPriceDecimal,
  useWindowDimensions,
  dateFormat,
} from './general/General';

function showAlert() {
  alert('test');
}

const appendScript = (scriptToAppend: any) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
}

export {
  showAlert,
  setCookie, getCookie, decodeString, encodeString,
  appendScript,
  setSession,
  getSession,
  updateSession,
  slugify,
  amountPriceDecimal,
  useWindowDimensions,
  dateFormat,
}