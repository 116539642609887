import { useState, useEffect } from 'react';

export function slugify(str: string) {
  str = str.replace(/^\s+|\s+$/g, '');
  // Make the string lowercase
  str = str.toLowerCase();
  // Remove accents, swap ñ for n, etc
  var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }
  // Remove invalid chars
  str = str.replace(/[^a-z0-9 -]/g, '')
  // Collapse whitespace and replace by -
  .replace(/\s+/g, '-')
  // Collapse dashes
  .replace(/-+/g, '-');
  return str;
}

export function amountPriceDecimal(price: number) {
  const amountCent = String(price);
  const convertCentToPrice = amountCent.substring(0, amountCent.length-2);
  const convertCentToPriceDecimal = amountCent.substring(amountCent.length-2,amountCent.length);
  const price_format = convertCentToPrice+'.'+convertCentToPriceDecimal;
  
  return Number.parseFloat(price_format).toFixed(2);
}

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  let device = 'desktop';

  if (width < 768) {
    device = 'mobile';
  } else {
    device = 'desktop';
  }
  return {
    device
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

type dateFormatProp = {
  dateToMonth?: any;
};

export const dateFormat: dateFormatProp = {
  dateToMonth: (time: any) => {
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    const date = new Date(time);
    return monthNames[date.getMonth()];    
  }
}